body { color: #4C4C4C; }

a:link, a:visited,
#navigation ul li a, #navigation ul.sf-menu li a { color: #4C4C4C; }

a:hover, a:active, a:focus { color: #C7CC43; }

h1, h2, h3, h4, h5, h6 { color: #C7CC43; text-shadow: 1px 1px 0 #FAFAFA; }

#header #site-name a { color: #4C4C4C; text-shadow: 1px 1px 0 #FAFAFA; }

#header #site-name a:hover { color: #939498; }

#wrap { background-color: #F7F7FA; }

#footer {
  background-image: linear-gradient(bottom, #B4BA36 47%, #C7CC43 74%);
  background-image: -o-linear-gradient(bottom, #B4BA36 47%, #C7CC43 74%);
  background-image: -moz-linear-gradient(bottom, #B4BA36 47%, #C7CC43 74%);
  background-image: -webkit-linear-gradient(bottom, #B4BA36 47%, #C7CC43 74%);
  background-image: -ms-linear-gradient(bottom, #B4BA36 47%, #C7CC43 74%);

  background-image: -webkit-gradient(
                  linear,
                  left bottom,
                  left top,
                  color-stop(0.47, #B4BA36),
                  color-stop(0.74, #C7CC43)
  );
}

#footer { border-color:#d6d6d6; }

.flex-direction-nav a { color: #C7CC43; }

