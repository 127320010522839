.page-gallery {

  /* This is the main content. */
  #middle-container {

    /* Reset the ul in this page. */
    ul {
      padding: 0;
      margin: 0;
    }

    /* This is the gallery view. */
    .view-display-gallery {
      .view-content {
        .views-row {
          display: none;
          // margin-bottom: 1em;
          // position: relative;
          // @include breakpoint($mobile-small) {
          //   max-width: $mobile-big;
          // }
          // @include breakpoint($mobile-big) {
          //   lost-column: 1/2 2 1em;
          // }
          // @include breakpoint($desktop) {
          //   lost-column: 1/3 3 1em;
          // }

          // The outer div of the photos.
          // .item-list {
          //   padding-bottom: 0;
          //   // position: absolute;
          //   // left: 0;
          //   // bottom: 0;
          //   background: red;
          // }

          // .field-name-field-photos .field-item {
          //   overflow: hidden;
          //   img {
          //     -webkit-transition: all 1s ease;
          //        -moz-transition: all 1s ease;
          //         -ms-transition: all 1s ease;
          //          -o-transition: all 1s ease;
          //             transition: all 1s ease;
          //   }
          //   img:hover {
          //     -webkit-transform:scale(1.10); /* Safari and Chrome */
          //       -moz-transform:scale(1.10); /* Firefox */
          //       -ms-transform:scale(1.10); /* IE 9 */
          //       -o-transform:scale(1.10); /* Opera */
          //        transform:scale(1.10);
          //   }
          // }
          // .views-field-title {
          //   position: absolute;
          //   bottom: 5px;
          //   background: rgba(black, 0.7);
          //   padding: 10px;
          //   width: 500px;
          //   width: 100%;
          // }
        }
      }
    }
  }
}
