body.node-type-training-course-display {

  .group-header {

    //@include breakpoint($iPad_landscape) {
    background-color: pink;
    //  height: 150px;
    //}

    @include breakpoint($tablet) {
      //height: 50px;
      //background-color: red !important;
    }

    @include breakpoint($desktop) {
      //height: 50px;
      //background-color: red !important;
    }

    //form.commerce-add-to-cart {
    //  > div {
    //    background-color: red;
    //    //@include clearfix;
    //    //@include background-grid();
    //
    //    /* The quantity. */
    //    .form-item-quantity {
    //      @include breakpoint($tablet) {
    //        @include grid-span(3, 1);
    //        margin-top: 2px;
    //      }
    //      @include breakpoint($desktop) {
    //        @include grid-span(2, 1);
    //        //margin-top: 2px;
    //      }
    //    }
    //
    //    /* The price. */
    //    .rs-price-display {
    //      //background-color: pink;
    //      text-align: left;
    //      @include breakpoint($tablet) {
    //        @include grid-span(2, 3.5);
    //        margin-top: -8px;
    //      }
    //
    //      @include breakpoint($desktop) {
    //        @include grid-span(2, 3);
    //        //margin-top: -8px;
    //      }
    //    }
    //
    //    /* The "Book" now button. */
    //    input.form-submit {
    //      @include breakpoint($tablet) {
    //        @include grid-span(3, 5.5);
    //        margin-top: 1px;
    //        //padding: 5px 0;
    //      }
    //
    //      @include breakpoint($desktop) {
    //        @include grid-span(2, 4.7);
    //        //margin-top: 1px;
    //        padding: 5px 0;
    //      }
    //    }
    //  }
    //}

    /* The flexbox edition. */
    form.commerce-add-to-cart {
      > div {
        display: flex;
        flex-direction: column;
        @include breakpoint($tablet) {
          flex-direction: row;
          align-items: center;
        }

        /* The price. */
        .rs-price-display {
          //background-color: red;
          text-align: left;
          order: 2;
          margin: 0 20px 0 0;

          .form-item {
            margin: 0;
          }
        }

        /* The quantity. */
        .form-item-quantity {
          order: 1;
          margin: 0 20px 0 0;
          input {
            margin: 0;
          }
        }

        /* The "Book" now button. */
        input.form-submit {
          order: 3;
          margin: 0;
        }
      }
    }
  }

  .group-middle {
    @include clearfix;
    //background-color: green;
    width: 100% !important;
    @include breakpoint($tablet) {
      width: 70% !important;
    }
    @include breakpoint($desktop) {
      width: 75% !important;
    }
    //background-color: green;

    /* The image (logo). */
    .field-name-node-training-logo {
      @include breakpoint($desktop) {
        margin-bottom: -20px;
      }
      img {
        max-width: 40%;
        height: auto;
      }
    }

    /* The fields below the logo. */
    .commerce-product-field-field-venue,
    .commerce-product-field-field-duration,
    .commerce-product-field-field-maximum-attendees,
      //.commerce-product-field-commerce-stock,
    .commerce-product-field-field-places-available,
    .commerce-product-field-field-date {
      @include breakpoint($desktop) {
        width: 100%;
        float: left;
        clear: left;
      }
    }

    /* This is the Date field on the left. */
    .commerce-product-field-field-date {
      @include breakpoint($desktop) {
        font-size: 0.9em;
      }
    }
  }

  .group-right {
    //@include clearfix;
    //overflow: visible;
    //background-color: red;

    h2 {
      text-align: left;
    }

    width: 90% !important;
    float: left;

    @include breakpoint($tablet) {
      width: 30% !important;
    }
    @include breakpoint($desktop) {
      width: 25% !important;
    }

    // The calendar widget.
    .view-display-id-entity_view_1 {
      //background-color: yellow;
      //@include clearfix;

      @include breakpoint($desktop) {
        //width: 70%;
        width: 250px;
        //height: 142px;
        float: left;
        //margin-top: -84px;
        // The margin-top difference is due to  the "Venue" that is not available in the other training products.
        //margin-top: -69px;
      }

      .view-content {
        .calendar-calendar {
          .month-view .full tr.single-day .no-entry,
          .month-view .full tr.single-day .no-entry .inner {
            height: 0 !important;
            //line-height: 44px;
          }

          .month-view .full td.single-day div.monthview {
            // Green background to the dates of availability.
            background: $green;
          }

          .day {
            // Remove the padding because the 2 digit dates were breaking.
            padding: 0;
          }
        }
      }

      // The bottom of the calendar with the pager.
      .date-nav-wrapper {
        //background-color: red;
        h3 {
          font-size: 1.2em;
        }

        .date-nav {
          //@include background-grid();
          //@include clearfix;
          // Reduce the overall height.
          height: 35px;
          .date-heading {
            //@include grid-span(1, 6);
            margin-left: -64px;
          }
          ul.pager {
            //@include grid-span(7, 6);
          }
        }
      }
    }
  }

}

