body.page-node-16 {
  .group-left {
    @include grid-span(12, 1);
    @include breakpoint($tablet) {
      @include grid-span(6, 1);
    }
  }
  .group-right {
    @include grid-span(12, 1);
    @include breakpoint($tablet) {
      @include grid-span(6, 7);
    }
  }

  //Styles for the "Contact us by clicking the following locations:" block.
  .company-location {
    h3 {
      cursor: pointer;
    }

    // Hide all info for each location.
    .company-container {
      display: none;
      @include clearfix;
      //@include background-grid();
      margin-bottom: ($default-padding*2);

      .address-container {
        @include grid-span(4, 1);
      }

      .details-container {
        @include grid-span(8, 5);
      }
    }
  }

}



