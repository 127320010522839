body.front {

  // The slideshow.
  .flexslider {
    .image {
      position: relative;

      .title {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: rgba($green, 0.8);
        color: white;

        p {
          padding: 0;
          padding-left: 25px;
          font-size: 85%;
          @include breakpoint($tablet) {
            padding-left: 60px;
            font-size: initial;
          }
          padding-right: 100px;
          cursor: pointer;
          margin-bottom: -1px;
        }

        .body {
          display: none;
        }
      }
    }
  }

  .container-highlighted-center {
    .body {
      background-color: rgba($green, 01);
      color: white;
      padding: $default-padding;
      margin-left: 10px;
      margin-right: 10px;
      @include breakpoint($tablet) {
        max-width: 1155px;
        margin-left: 31px;
      }
    }
  }
}

// What follows is the css for the arrow that was used in the original front page slideshow.

//.front .flexslider .slides .toggle-menu-up:after {
//  background-image: url(/sites/all/themes/skeletontheme_testing/images/b_arrow_down.png);
//  content: ' ';
//  display: block;
//  width: 50px;
//  height: 40px;
//  position: absolute;
//  top: 8px;
//  background-repeat: no-repeat;
//  right: 12%;
//}