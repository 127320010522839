// For globbing I am using this: https://www.npmjs.com/package/gulp-sass-glob
//@import 'modular-scale';
// @import "singularity-extras";
@import "compass";
@import "singularitygs";
@import "breakpoint";
@import "variables/**/*.scss";
@import "former_css/_skeleton.scss";
@import "former_css/_style.scss";
@import "former_css/_buttons.scss";
@import "former_css/_layout.scss";
@import "former_css/_colors.scss";
@import "former_css/_media.scss";
@import "../js/flexnav/css/_flexnav.scss";
@import "../js/hoverscroll/_jquery.hoverscroll.scss";

@import "base/**/*.scss";
@import "components/**/*.scss";
@import "drupal-node-types/**/*.scss";
@import "drupal-pages/**/*.scss";
@import "drupal-regions/**/*.scss";

// This is the input field at the search results page.
#search-form input#edit-keys {
  height: 30px;
}

/* This is the caption below the main slideshow.
 * I fixed the width and the position.
 */
#custom-flexslider-content .toggle-body {
  @include breakpoint($tablet) {
    left: 32px;
    width: 90%;
  }
}

// This is for the banner cog.
// It kept disappearing
// so I gave it a little height and width.
html.js div.contextual-links-wrapper {
  display: block;
  width: 5%;
  height: 5%;
  //background-color: red;
}

#block-system-main {
  h2 {
    line-height: 100%;
  }
}


.field-type-file {
  color: #b1bb1b;
}

.red {
  color: red !important;
}

.display-block {
  display: block;
  padding-left: 20px;
}