@import url(https://fonts.googleapis.com/css?family=PT+Sans:400,700,400italic,700italic);
@-ms-viewport{
    width: device-width;
}

body { font-size: 100%; font-weight:normal; font-style:normal; line-height: 1.5; background:#F7F7FA; word-wrap: break-word; margin: 0; padding: 0; border: 0; outline: 0;/*border-top:10px solid;*/  }

a:link, a:visited { text-decoration: none;  }

a:hover, a:active, a:focus { text-decoration: none; }

#main a { text-decoration: none; }

#main a:hover { text-decoration: none; }

#main h1 a, #main h2 a, #main h3 a, #main h4 a, #main h5 a { text-decoration: none; }

h1, h2, h3, h4, h5, h6 { letter-spacing: -1px; margin:0; }

h1 { font-size:35px; font-weight:normal; font-style:normal; line-height: 140%; margin-bottom: 14px; }

h2 { font-size:28px; font-weight:normal; font-style:normal; line-height: 130%; margin-bottom: 10px; }

.block h2, #footer h2 { font-weight: bold; font-size: 1.5em; margin-bottom: 10px; /* Additional */ color: #C7CC43; line-height: 50%; border-bottom: 1px solid #C7CC43; padding-bottom: 10px;}

h3 { font-size:21px; font-weight:normal; font-style:normal; line-height: 130%; margin-bottom: 8px; }

h4 { font-size:17px; font-weight:bold; font-style:bold; line-height: 125%; margin-bottom: 8px; }

h5 { font-size:14px; font-weight:bold; font-style:bold; line-height: 120%; margin-bottom: 8px; }

h1 a, h2 a, h3 a, h4 a, h5 a { font-weight: inherit; text-decoration: none; }

p { margin: 0 0 20px 0; text-align: justify; /* Added by Mary */}

del { text-decoration: line-through; }

pre,code { white-space: pre; background-color: #ededed; border-bottom: 1px solid #d9d9d9; border-top: 1px solid #d9d9d9;
display: block; margin: 5px 0; padding: 5px; line-height: 125%; overflow: hidden;
font: 12px/24px Consolas,"Andale Mono WT","Andale Mono","Lucida Console","Lucida Sans Typewriter","DejaVu Sans Mono","Bitstream Vera Sans Mono","Liberation Mono","Nimbus Mono L",Monaco,"Courier New",Courier,monospace; }

hr { border: solid #DDD; clear: both; margin: 10px 0 30px; height: 0; border-width: 1px 0 0; }

.field-type-taxonomy-term-reference .field-item { float: left; padding: 0 1em 0 0; white-space: nowrap; }

/* Responsive Images */

img, img.scale-with-grid { outline: 0; max-width: 100%; height: auto; }

/* Fonts */

body,input, textarea, select, a.button, fieldset legend { font-family: "PT Sans", "HelveticaNeue", "Helvetica Neue", Helvetica, Arial, sans-serif; }

/* Downloads - Documentation */

.cta { margin: 10px auto 0px auto; padding: 10px 10px 20px 10px; background-color: #f2f2f2; position: relative;
border: 1px solid #dcdcdc;
-webkit-border-radius:8px; -moz-border-radius:8px; border-radius:8px; text-shadow: #fff 1px 1px 0;
box-shadow: 1px 1px 0 #fff inset, -1px -1px 0 #fff inset; -moz-box-shadow: 1px 1px 0 #fff inset, -1px -1px 0 #fff inset;
-webkit-box-shadow: 1px 1px 0 #fff inset, -1px -1px 0 #fff inset; }

.cta h3 { font-size: 1.5em; text-shadow: #fff 1px -1px 0; }

/* Image Alignment Classes */
.alignright { float: right; margin: 0px 0px 10px 15px; }

.alignleft { float: left; margin: 0px 15px 10px 0px; }

.aligncenter { display:block; margin:0 auto; }

/* Header */
#header-banner { position: relative; z-index: 1; background:#939498;}
#header-top { padding:20px 0 4px 0; }

#header .inner, #header-right .inner { padding-top: 5px; padding-bottom: 10px; }

#logo { float: left; /*padding: 0 20px;*/ }

#site-name { margin: 0; padding: 0; font-size: 60px; line-height: 55px; letter-spacing: -1px; display: none; }

#header #site-name a { margin: 0; padding: 0; line-height: 55px; text-decoration: none; font-weight: bold;
letter-spacing: -2px; text-shadow: 0px 1px 0px #FFF; }

#header #site-name a:hover { color: #3253ad; }

#header #site-slogan { margin: 0; padding: 0; font-size: 24px; line-height: 28px; letter-spacing: -1px; }

/* Breadcrumb */

.breadcrumb { padding:0; }

#breadcrumb { overflow: hidden; zoom: 1; margin: 0 0 1.5em !important; padding: 5px 5px 5px 10px;
font-size: 0.92em; color: #999 !important; background-color: #f1f1f1;
-webkit-border-radius:4px; -moz-border-radius:4px; border-radius:4px; border: 1px solid #e9e9e9;
text-shadow: 1px 1px 0px #fff; }

#breadcrumb a { color: #797979; margin-right: 6px; text-shadow: none; text-decoration:underline; }

#breadcrumb .sep { margin-right: 6px;	 }

/* Main */

.node.node-teaser { margin-bottom: 20px; padding-bottom: 10px; /*border-bottom: 1px solid #d6d6d6;*/ /* Commented out by Mary */ }

.submitted { font-size: 11px; color: #666666; padding-bottom: 10px; margin-bottom: 10px; }

/* Footer */

/*#footer { clear: both; padding-top: 20px; border-top: 1px solid; font-size: .9em; }*/

#footer .block { padding: 0 0 20px 0; }

/* Help */

.region-help { border: 1px solid #d3d7d9; padding: 20px; margin: 20px 0; }

/* Table Styles */

table { border: 0; border-spacing: 0; margin: 10px 0; width: 100%; }

table table { font-size: 1em; }

table tr th { border-bottom: 1px solid white; border-top: 1px solid #dddddd; font-weight: normal;
color: #707070; text-shadow: white 1px 1px 0; background: #dddddd url(../images/gradient_small_trans.png) repeat-x 0 top; }

th { padding:5px 15px; border:none; }

td { padding:15px; border:none; }

table tr th, table tr th a, table tr th a:hover { font-weight: bold; }

table tbody tr th { vertical-align: top; }

tr.odd { background-color: #F4F4F4; border-bottom: 1px solid #EBEBEB; border-top: 1px solid white; }

tr, tr.even { background-color: #F9F9F9; border-bottom: 1px solid #EDEDED; border-top: 1px solid white; }

/* List Styles */

.block ol, .block ul { margin: 0; padding: 0 0 0.25em 1em; }

.contextual-links-wrapper { font-size: small !important; }

ul.contextual-links { font-size: 0.923em; }

.contextual-links-wrapper a { text-shadow: 0 0 0 !important; }

.item-list .pager { font-size: 0.929em; }

ul.menu li {  margin: 0; }

.region-content ul, .region-content ol { margin: 1em 0; padding: 0 0 0.25em 2.5em; }

.item-list ul li { margin: 0; padding: 0.2em 0.5em 0 0;  }

ul.links { font-size: 11px; color: #666666; }

ul.links.inline li { padding:0 5px 0 0; margin:0 5px 0 0; border-right:1px solid #666666; }

ul.links.inline li.last { border:none; }

/* Menus */

.block ul.menu { padding: 0; margin: 0; list-style: none; }

.block ul.menu li { list-style:none; }

.block ul.menu li a { display: block; border-bottom: 1px solid #eee; padding: 8px 0px 8px 24px; line-height: 120%; font-weight: bold;
background: url(../images/light/arrow_bullet.png) no-repeat 10px; text-decoration: none; }

.block ul.menu li a:hover, .block ul.menu li a.active { color:#C7CC43; background: url(../images/arrow_bullet_green.png) no-repeat 10px; }

.block ul.menu ul { /*margin:0 0 0 1.3em;*/margin: 0; }

.block ul.menu ul li a { border:none; padding: 4px 0; /*font-size:13px;*/ color:#666666; background:none; }

.block ul.menu ul li a:hover { color:#333333; background:none; }

.block.block-superfish ul.menu li a { background:none; }

/* Comments */

.comment h2.title { margin-bottom: 1em; }

.comment div.user-picture img { margin-left: 0; }

.comment { margin-bottom: 20px; display: table; vertical-align: top; }

.comment .attribution { display: table-cell; padding: 0 35px 0 0; vertical-align: top; overflow: hidden; }

.comment .attribution img { margin: 0; border: 1px solid #d3d7d9; }

.comment .attribution .username { white-space: nowrap; }

.comment .submitted p { margin: 4px 0; font-size: 1.071em; line-height: 1.2; }

.comment .submitted .comment-time { font-size: 0.786em; }

.comment .submitted .comment-permalink { font-size: 0.786em; text-transform: lowercase; }

.comment .content { font-size: 0.929em; line-height: 1.6; }

.comment .comment-arrow { background: url(../images/comment-arrow.gif) no-repeat 0 center transparent;
border-left: 1px solid; border-right: 1px solid; height: 40px; margin-left: -36px; margin-top: 15px; position: absolute; width: 20px; }

.comment .comment-text { padding: 0 25px 10px 35px; display: table-cell; vertical-align: top; position: relative; width: 100%; border-left: 1px solid #d3d7d9; }

.comment .indented { margin-left: 40px; }

.comment-unpublished { margin-right: 5px; padding: 5px 2px 5px 5px; }

.comment .comment-text .comment-arrow { border-left: 1px solid #ffffff; border-right: 1px solid #ffffff; }

/* Sidebar */

#wrap #sidebar-first, #wrap #sidebar-second {
	float:left;
	display: block;
	width: 20%;
	margin-right: 2%;
	margin-left: 0;
}

.content-wrap {
	display: block;
	float: left;
	width: 78%;
}

/*#sidebar-first .block, #sidebar-second .block { padding: 0 0 20px 0; }*/

/* Messages */

#messages { padding: 20px 0 5px; margin: 0 auto; }

div.messages { margin: 10px 0; }

/* -------------- User Profile   -------------- */

.profile .user-picture { float: none; }

/* -------------- Form Elements   ------------- */

fieldset { background: #ffffff; border: 1px solid #cccccc; margin-top: 10px; margin-bottom: 32px;
padding: 0 0 10px; position: relative; top: 12px; /* Offsets the negative margin of legends */
-khtml-border-radius: 4px; -moz-border-radius: 4px; -webkit-border-radius: 4px; border-radius: 4px; }

.fieldset-wrapper { margin-top: 25px; }

.node-form .vertical-tabs .fieldset-wrapper { margin-top: 0; }

.filter-wrapper { top: 0; padding: 1em 0 0.2em;
-khtml-border-radius-topright: 0; -khtml-border-radius-topleft: 0; -moz-border-radius-topright: 0; -moz-border-radius-topleft: 0;
-webkit-border-top-left-radius: 0; -webkit-border-top-right-radius: 0; border-top-left-radius: 0; border-top-right-radius: 0; }

.filter-help a { font-size: 0.857em; padding: 2px 20px 0; }

.filter-wrapper .form-item label { margin-right: 10px; }

.filter-wrapper .form-item { padding: 0 0 0.5em 0.5em; }

.filter-guidelines { padding: 0 1.5em 0 0.5em; }

fieldset.collapsed { background: transparent; -khtml-border-radius: 0; -moz-border-radius: 0;  -webkit-border-radius: 0; border-radius: 0; }

fieldset legend { background: #dbdbdb; border: 1px solid #ccc; border-bottom: none; display: block; height: 2em;
left: -1px; line-height: 2; padding: 0; position: absolute; text-indent: 10px; text-shadow: 0 1px 0 #fff; top: -12px; width: 100%;
-khtml-border-radius-topleft: 4px; -moz-border-radius-topleft: 4px; -webkit-border-top-left-radius: 4px; border-top-left-radius: 4px;
-khtml-border-radius-topright: 4px; -moz-border-radius-topright: 4px; -webkit-border-top-right-radius: 4px; border-top-right-radius: 4px; }

fieldset.collapsed legend { -khtml-border-radius: 4px; -moz-border-radius: 4px; -webkit-border-radius: 4px; border-radius: 4px; }

fieldset .fieldset-wrapper { /*padding: 0 10px;*/padding: 0; }

fieldset .fieldset-description { margin-top: 5px; margin-bottom: 1em; line-height: 1.4; font-style: italic; }

input { margin: 2px 0; padding: 4px; }

input, textarea {  font-size: 0.929em; }

textarea { line-height: 1.5; }

textarea.form-textarea, select.form-select { padding: 4px; }

input.form-text, textarea.form-textarea, select.form-select { border: 1px solid #ccc; }

.password-suggestions ul li { margin-left: 1.2em; }

.form-item { margin-bottom: 1em; margin-top: 2px; }

.form-item label { font-size: 0.929em; }

.form-type-radio label, .form-type-checkbox label { margin-left: 4px; }

.form-type-radio .description, .form-type-checkbox .description { margin-left: 2px; }

.form-actions { padding-top: 10px; }

/* Contact Form */

.contact-form #edit-name { width: 100%;
-moz-box-sizing: border-box; -webkit-box-sizing: border-box; box-sizing: border-box;
-khtml-border-radius: 4px; -moz-border-radius: 4px; -webkit-border-radius: 4px; border-radius: 4px; }

.contact-form #edit-mail { width: 100%;
-moz-box-sizing: border-box; -webkit-box-sizing: border-box; box-sizing: border-box;
-khtml-border-radius: 4px; -moz-border-radius: 4px; -webkit-border-radius: 4px; border-radius: 4px; }

.contact-form #edit-subject { width: 100%;
-moz-box-sizing: border-box; -webkit-box-sizing: border-box; box-sizing: border-box;
-khtml-border-radius: 4px; -moz-border-radius: 4px; -webkit-border-radius: 4px; border-radius: 4px; }

.contact-form #edit-message { width: 100%;
-moz-box-sizing: border-box; -webkit-box-sizing: border-box; box-sizing: border-box;
-khtml-border-top-left-radius: 4px; -khtml-border-top-right-radius: 4px; -moz-border-radius-topleft: 4px;
-moz-border-radius-topright: 4px; -webkit-border-top-left-radius: 4px; -webkit-border-top-right-radius: 4px;
border-top-left-radius: 4px; border-top-right-radius: 4px; }

.contact-form .resizable-textarea .grippie {  width: 100%;
-moz-box-sizing: border-box; -webkit-box-sizing: border-box; box-sizing: border-box;
-khtml-border-bottom-left-radius: 4px; -khtml-border-bottom-right-radius: 4px; -moz-border-radius-bottomleft: 4px;
-moz-border-radius-bottomright: 4px; -webkit-border-bottom-left-radius: 4px; -webkit-border-bottom-right-radius: 4px;
border-bottom-left-radius: 4px; border-bottom-right-radius: 4px; }

/* Animated throbber */

html.js input.form-autocomplete { background-position: 100% 4px; }

html.js input.throbbing { background-position: 100% -16px; }

/* Comment form */

.comment-form label { float: left; font-size: 0.929em; }

.comment-form input, .comment-form .form-select { margin: 0;
-khtml-border-radius: 4px; -moz-border-radius: 4px; -webkit-border-radius: 4px; border-radius: 4px; }

.comment-form .form-type-textarea label { float: none; }

.comment-form .form-item,
.comment-form .form-radios,
.comment-form .form-type-checkbox,
.comment-form .form-select { margin-bottom: 10px; overflow: hidden; }

.comment-form .form-type-checkbox,
.comment-form .form-radios { margin-left: 120px; }

.comment-form .form-type-checkbox label,
.comment-form .form-radios label { float: none; margin-top: 0; }

.comment-form input.form-file { width: auto; }

.comment-form .form-text { display: block; margin: 0; width: 100%;
-moz-box-sizing: border-box; -webkit-box-sizing: border-box; box-sizing: border-box; }

.comment-form .form-item .description { font-size: 0.786em; line-height: 1.2; margin-left: 120px; }

#content h2.comment-form { margin-bottom: 0.5em; }

.comment-form .form-textarea { -khtml-border-radius-topleft: 4px; -khtml-border-radius-topright: 4px;
-moz-border-radius-topleft: 4px; -moz-border-radius-topright: 4px;
-webkit-border-top-left-radius: 4px; -webkit-border-top-right-radius: 4px;
border-top-left-radius: 4px; border-top-right-radius: 4px; }

.comment-form fieldset.filter-wrapper .fieldset-wrapper,
.comment-form .text-format-wrapper .form-item { margin-top: 0; margin-bottom: 0; }

.filter-wrapper label { width: auto; float: none; }

.filter-wrapper .form-select { min-width: 120px; }

.comment-form fieldset.filter-wrapper .tips { font-size: 0.786em; }

#comment-body-add-more-wrapper .form-type-textarea label { margin-bottom: 0.4em; }

#edit-actions input { margin-right: 0.6em; }

/* Other Overrides */

div.password-suggestions { border: 0; }

.ui-widget-overlay { background: #222222; opacity: 0.7; }

div.vertical-tabs .vertical-tabs-panes fieldset.vertical-tabs-pane { padding: 1em; }

#forum .name { font-size: 1.083em; }

#forum .description { font-size: 1em; }

/* --------------- Search Form ---------------- */

#block-search-form { padding-bottom: 7px; }

#block-search-form .content { margin-top: 0; }

#search-form input#edit-keys,
#block-search-form .form-item-search-block-form input { float: left; font-size: 1em; height: 1.143em; margin-right: 5px; width: 9em; }

#search-block-form input.form-submit,
#search-form input.form-submit { margin-left: 0; margin-right: 0; height: 25px; width: 34px; padding: 0; cursor: pointer;
text-indent: -9999px; border-color: #e4e4e4 #d2d2d2 #b4b4b4; background: url(../images/search-button.png) no-repeat center top; overflow: hidden;
filter:none; }

#search-block-form input.form-submit:hover,
#search-block-form input.form-submit:focus,
#search-form input.form-submit:hover,
#search-form input.form-submit:focus { background-position: center bottom; }

#search-form .form-item-keys label { display: block; }

/* Search Results */
ol.search-results { padding-left: 0; }

.search-results li { border-bottom: 1px solid #d3d7d9; padding-bottom: 0.4285em; margin-bottom: 0.5em; }

.search-results li:last-child { border-bottom: none; padding-bottom: none; margin-bottom: 1em; }

.search-results .search-snippet-info { padding-left: 0; }

/* Navigation menu*/
#navigation ul, #navigation ul li,
#navigation ul.sf-menu, #navigation ul.sf-menu li { float: left; margin: 0; padding: 0;  }

#navigation ul, #navigation ul.sf-menu { width: 100%;
border-color: #EAEAEA #F5F5F5 #F5F5F5 #F5F5F5;
-moz-border-radius: 5px 5px 5px 5px; -webkit-border-radius: 5px 5px 5px 5px; border-radius: 5px 5px 5px 5px;
border-style: solid; border-width: 1px;
box-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
/* IE10 */
background-image: -ms-linear-gradient(top, #fcfcfc 0%, #ececec 100%);
/* Mozilla Firefox */
background-image: -moz-linear-gradient(top, #fcfcfc 0%, #ececec 100%);
/* Opera */
background-image: -o-linear-gradient(top, #fcfcfc 0%, #ececec 100%);
/* Webkit (Safari/Chrome 10) */
background-image: -webkit-gradient(linear, left top, left bottom, color-stop(0, #fcfcfc), color-stop(1, #ececec));
/* Webkit (Chrome 11+) */
background-image: -webkit-linear-gradient(top, #fcfcfc 0%, #ececec 100%);
/* Proposed W3C Markup */
background-image: linear-gradient(top, #fcfcfc 0%, #ececec 100%);
/* IE6-9 */
filter:  progid:DXImageTransform.Microsoft.gradient(startColorstr='#fcfcfc', endColorstr='#ececec'); }

#navigation ul li, #navigation ul.sf-menu li { position: relative; list-style:none; border-right: 1px solid #e3e3e3; background:none; }

#navigation ul li a, #navigation ul.sf-menu li a { padding: 15px 25px; text-decoration: none; line-height:120%;
display: block; font-weight: bold; text-shadow: 1px 1px 0px #fff; outline:none; }

#navigation ul li.expanded a, #navigation ul.sf-menu li.menuparent a { padding-right: 35px; }

#navigation ul li a:hover, #navigation ul.sf-menu li a:hover { position: relative; color: #545454; background-color: #f1f1f1; }

#navigation ul li:hover li a, #navigation ul.sf-menu li li a { color: #595959; font-weight: normal; }

#navigation ul li a.active, #navigation ul.sf-menu li a.active { color: #333333; background-color: #ffffff; text-shadow: 1px 1px 0px #ffffff; }

#navigation ul ul { position: absolute; top: -999em; width: 230px; }

#navigation ul.sf-menu ul li, #navigation ul ul li { width: 100%; }

#navigation ul.sf-menu li:hover, #navigation ul li:hover { visibility: inherit; }

#navigation ul.sf-menu li, #navigation ul li  { float: left; position: relative; }

#navigation ul.sf-menu a, #navigation ul a { display: block; position: relative; }

#navigation ul.sf-menu li:hover ul,
#navigation ul.sf-menu li.sfHover ul,
#navigation ul li:hover ul { left: 0; top: 48px; z-index: 999; }

#navigation ul li:hover ul { top:46px; }

#navigation ul.sf-menu li:hover li ul,
#navigation ul.sf-menu li.sfHover li ul,
#navigation  ul li:hover li ul { top: -999em; }

#navigation ul.sf-menu li li:hover ul,
#navigation ul.sf-menu li li.sfHover ul,
#navigation  ul li li:hover ul { left: 230px; top: 0; }

#navigation ul.sf-menu li li:hover li ul,
#navigation ul.sf-menu li li.sfHover li ul,
#navigation ul li li:hover li ul { top: -999em; }

#navigation ul.sf-menu li li li:hover ul,
#navigation ul.sf-menu li li li.sfHover ul,
#navigation  ul li li li:hover ul { left: 230px; top: 0; }

#navigation ul ul { background:none; filter:none; border:none; box-shadow: none;
-moz-border-radius: 0; -webkit-border-radius: 0; border-radius: 0; }

#navigation ul ul li { border:none; }

#navigation ul ul li a, #navigation ul ul li a.active,
#navigation ul.sf-menu ul li a, #navigation ul.sf-menu ul li a.active {
background-color: #f0f0f0; border:none; padding: 10px; }

#navigation ul ul li a:hover,
#navigation ul.sf-menu ul li a:hover,
#navigation ul.sf-menu ul li.sfHover > a { background-color:#ffffff; }

/*css drop down menu arrows*/
#navigation li.expanded > a, #navigation li.menuparent > a  {
background: transparent url('../images/light/arrow_bullet.png') no-repeat 90% 50%; }

#navigation li li.expanded > a {
background: #f0f0f0 url('../images/light/arrow_bullet.png') no-repeat 90% 50%; }

#navigation li li.expanded:hover > a { background-color:#ffffff; }

#navigation li.expanded:hover > a, #menu li.expanded:active > a,
#navigation li.menuparent:hover > a, #menu li.menuparent:active > a {
background: #f0f0f0 url('../images/dark/arrow_bullet.png') no-repeat 90% 50%; }

/*
* Superfish support: Reset background/border
*/
#navigation .sf-menu a { border:none; }
#navigation .sf-menu li { background-color:none; border-top:none; }
#navigation .sf-menu li li { border:none; }

/* iPad Portrait/Browser */
@media only screen and (max-width: 991px) {

#navigation ul, #navigation ul.sf-menu li a { border-radius:0px; }

#navigation ul li a, #navigation ul.sf-menu li a { font-size: .9em;padding: 10px 15px; }

#header #site-slogan { font-size: 1.25em; }

#navigation ul.sf-menu li:hover ul,
#navigation ul.sf-menu li.sfHover ul,
#navigation ul li:hover ul { top: 35px; }

}

/* Mobile/Browser */
@media only screen and (max-width: 767px) {
select.mnav { width:100%; padding:5px;}
}

#header-right ul.menu, #header-top ul.menu { padding:0; margin:0; display:block; overflow:hidden; text-align:right; padding:20px 0 0 0; }

#header-top ul.menu { text-align:left; padding:0; }

#header-top .region-header-top-left ul.menu { text-align:left; }

#header-top .region-header-top-right ul.menu { text-align:right; }

#header-right ul.menu li, #header-top ul.menu li  { border-right:1px solid #e3e3e3; display:inline; list-style-type:none; overflow:hidden; padding:0 10px; margin:0; }

#header-right ul.menu li a, #header-top ul.menu li a  { background:none; display:inline; border:none; padding:0; }

#header-right ul.menu li.first, #header-top ul.menu li.first { padding-left:0; margin:0; }

#header-right ul.menu li.last, #header-top ul.menu li.last { border-right:none; padding-right:0; margin:0; }

#header-right ul.menu ul.menu, #header-top ul.menu ul.menu  { display:none; }

/* Mobile/Browser */
/*@media only screen and (max-width: 767px) {
#header-right ul.menu li, #header-top ul.menu li { display:block; text-align:center; border-right:none; padding:5px 0; background: #E3E3E3; }
}*/

/* Custom styles */

/* General */

#footer { padding: 0; border-top: 1px solid; font-size: 100%; clear:both; }
/*#footer .region-footer-third .block { padding: 1%; }*/
#footer .one_third { margin-bottom: 0; }
#footer .region-footer-first a, #footer .region-footer-first a:hover { color: #4C4C4C; }
#footer .region-footer-first a { background: none; border-bottom: none; }
.views-slideshow-controls-top {display:none;}

.front .node-promoted .link-wrapper { display: none; }
.front .node-promoted h2 {border: none;}

.header-container { background-color: #FFFFFF; }

#header {
	/*max-width: 1520px;*/
	max-width: 1280px;
  margin: 0 auto;
  padding: 0 10px;
}

#header-left {
	float: left;
  width: 20%;
}

#header-right {
  width: 80%;
}

#header .columns { margin: 0; }

.item-list ul li { list-style: none; }
/*#header-banner { margin-bottom: 10px; }*/
/*#main .region-content, #sidebar-first { margin: 0; }*/

#logo-rsdirect { width: 19%; float: right; }
#logo-training, #logo-onsite { width: 20%; float: right; }

#main {
	margin-bottom: 20px;
	/*width: 100%;*/
}

.page-taxonomy #main .region-content #block-system-main .content .view,
.page-services-projects #main .region-content #block-system-main .content .view,
#main .feed-icon { display: none; }
.flexslider .flex-direction-nav a { display: block; }

/* Navigation */
#block-system-main-menu {
	position: relative;
}
#block-system-main-menu h2  {
	cursor: pointer;
}
#block-system-main-menu .content {
	display: none;
}


/*#block-system-main-menu ul li {
	position: relative;
}*/

/*#block-system-main-menu ul li > ul {
  display: none;
}*/

/*#block-system-main-menu ul li > ul {
	position: absolute;
  display: block;
  top: 40px;
  left: 260px;
  background: #1f2024;

  -webkit-border-radius: 0 0 5px 5px;
  -moz-border-radius: 0 0 5px 5px;
  border-radius: 0 0 5px 5px;

  -webkit-transition: opacity .25s ease .1s;
  -moz-transition: opacity .25s ease .1s;
  -o-transition: opacity .25s ease .1s;
  -ms-transition: opacity .25s ease .1s;
  transition: opacity .25s ease .1s;
}*/


/* Taxonomy menu */
#block-menu-menu-hire-product-category ul.menu li a,
#block-menu-menu-sales-product-categories ul.menu li a,
#block-menu-menu-training-courses-menu ul.menu li a,
#block-menu-menu-gallery-history ul.menu li a {background: none repeat scroll 0 0 rgba(0, 0, 0, 0);font-weight: normal;border-bottom: none;}

#block-menu-menu-hire-product-category ul.menu li a,
#block-menu-menu-training-courses-menu ul.menu li a,
#block-menu-menu-gallery-history ul.menu li a {
	padding: 0.1em 0;
}


#block-menu-menu-hire-product-category ul.menu li a.active-trail,
#block-menu-menu-sales-product-categories ul.menu li a.active-trail,
#block-menu-menu-training-courses-menu ul.menu li a.active-trail,
#block-menu-menu-gallery-history ul.menu li a.active-trail { font-size: 100%; color: #C7CC43; /*border-bottom: 1px solid #EEEEEE;*/ font-weight: bold; }

#block-menu-menu-hire-product-category ul.menu li a ul li a,
#block-menu-menu-sales-product-categories ul.menu li a ul li a { font-size: 100%; }

#block-menu-menu-hire-product-category ul.menu li ul,
#block-menu-menu-sales-product-categories ul.menu li ul { margin: 0 0 0 0.8em; }

/* Flexslider */

.flexslider ul { padding: 0; }
.flexslider {background: none repeat scroll 0 0 rgba(0, 0, 0, 0);border: medium none;box-shadow: none;margin: 0;position: relative;}
.flex-control-nav { display: none; }

.flexslider .slides .sales-title, .flexslider .slides .hire-title {
    display: block;
    cursor: pointer;
    bottom: 0px;
    height: 30px;
    opacity: 0.9;
    padding-left: 10px;
    padding-top: 5px;
    position: absolute;
    z-index: 55;
    width: 99%;
    /* Fallback for web browsers that doesn't support RGBa */
    background: rgb(62, 56, 54) transparent;
    /* RGBa with 0.6 opacity */
    background: rgba(62, 56, 54, 0.5);
    /* For IE 5.5 - 7*/
    filter:progid:DXImageTransform.Microsoft.gradient(startColorstr=#993E3836, endColorstr=#993E3836);
    /* For IE 8*/
    -ms-filter: "progid:DXImageTransform.Microsoft.gradient(startColorstr=#993E3836, endColorstr=#993E3836)";
    color: #FAFAFA;
    font-size: 1.2em;
}

/* Newsletter Subscription Block */

#block-simplenews-272 {
    background-color: #676767;
    border: 1px solid #676767;
    color: #FFF;
    float: right;
    font-weight: normal;
    position: relative;
    text-shadow: none;
    border-radius: 0;
    height: 58px;
    width: 100%;
}

#block-simplenews-272 h2 {color: #FFF;font-weight: normal;text-shadow: none;color: #C7CC43;}

#block-simplenews-272 .content p { margin: 0; }
#block-simplenews-272 .simplenews-subscribe .form-item-mail label { display: none; }
#block-simplenews-272 .simplenews-subscribe .form-item-mail { float: left; margin-right: 0; margin-bottom: 0; width: 67%;}
#block-simplenews-272 .simplenews-subscribe .form-item-mail .form-text {border:1px solid #676767;border-radius:5px;width:90%;height:1.5em;margin-top: 0;}
.not-logged-in #block-simplenews-272 .simplenews-subscribe .form-submit {
    background: #E6E6E6;
    border-radius: 3px;
    float: right;
    font-size: 1em;
    margin-left: 0;
    padding: 0.5% 0;
    width: 33%;
}

.logged-in #block-simplenews-272 .simplenews-subscribe .form-submit {
    background: #E6E6E6;
    border-radius: 3px;
    float: right;
    font-size: 1em;
    margin-left: 0;
    padding: 1.5% 0;
    width: 39%;
}

#block-simplenews-272 .simplenews-subscribe .form-submit:hover { background-color: #D5D5D5; }

/* Copyright */
#copyright { width: 100%; text-align: center; }

/* Login, Register and Password pages */
#custom-user-login-form-page .login-links a { font-weight: bold; font-style: italic; }

#custom-user-register-form-page .back-button a,
#custom-user-password-page .back-button a {
    background-image: -moz-linear-gradient(center top , #FFF 0%, #E3E3E3 100%);
    border: 1px solid #DBDBDB;
    border-radius: 6px;
    box-shadow: 0 1px 0 0 #FFF inset;
    color: #333;
    font-weight: bold;
    padding: 8px 1em 9px;
    position: relative;
    text-shadow: 0 1px 0 #FFF;
}

#custom-user-register-form-page .back-button a:hover,
#custom-user-password-page .back-button a:hover {background-image: -moz-linear-gradient(center top , #DBDBDB 0%, #F2F2F2 100%);border-radius: 6px;box-shadow: 0 1px 0 0 #FFF inset;cursor: pointer;}
#user-register-form #edit-actions { float: left; margin: 0; }
#custom-user-register-form-page { padding-top: 2%; }
#user-pass #edit-actions { float: left; margin: 0; }
#custom-user-password-page { padding-top: 2%; }

/* Webform */
.node-webform .field-name-body {
    background-color: #F1F1F1;
    border-bottom: 1px solid #E9E9E9;
    border-left: 1px solid #E9E9E9;
    border-right: 1px solid #E9E9E9;
    color: #4C4C4C !important;
    overflow: hidden;
    padding: 5px 5px 0;
    text-shadow: 1px 1px 0 #FAFAFA;
}

.node-webform .field-name-body .company-location { padding: 5px; }
.node-webform .field-name-body .company-location .company-address  { padding-bottom: 0.5em; clear: both; }
.node-webform .field-name-body .company-location .company-address .company {float:left;width:40%;}
.node-webform .field-name-body .company-location .company-city-londone,
.node-webform .field-name-body .company-location .company-city-birmingham,
.node-webform .field-name-body .company-location .company-city-manchester,
.node-webform .field-name-body .company-location .company-city-londonw { clear: both; cursor: pointer; }
.node-webform .webform-client-form,
.node-webform .company-location .company-address { display: none; }
.node-webform .webform-client-form .form-item .form-text { width: 93%; }
.node-webform .webform-client-form #webform-component-comments textarea,
.node-webform .webform-client-form #webform-component-comments .grippie { width: 95%; }
.node-webform p { margin-right: 0.8em; text-align: justify; }
.node-webform #contact-us-toggle h3 { cursor: pointer ; }

/* Checkout progress bar */
ul.inline.commerce-checkout-progress,
ol.inline.commerce-checkout-progress { padding-left: 0; }

ul.inline.commerce-checkout-progress li,
ol.inline.commerce-checkout-progress li {
    list-style: none;
    background-color: #EFEFEF;
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;
    margin: 0 0 10px;
    padding: 15px 15px 15px 35px;
    position: relative;
    width: auto;
}

ul.inline.commerce-checkout-progress li.active:after,
ul.inline.commerce-checkout-progress li.last.active:after,
ol.inline.commerce-checkout-progress li.active:after,
ol.inline.commerce-checkout-progress li.last.active:after {background: url("../images/progress/bg_checkout_progress_active.png") no-repeat scroll right center rgba(0, 0, 0, 0);}

ul.inline.commerce-checkout-progress li:after,
ol.inline.commerce-checkout-progress li:after {
    background: url("../images/progress/bg_checkout_progress.png") no-repeat scroll right center rgba(0, 0, 0, 0);
    content: " ";
    display: block;
    float: right;
    font-size: 0;
    height: 50px;
    position: absolute;
    right: -25px;
    top: 0;
    width: 25px;
    z-index: 2;
}

ul.inline.commerce-checkout-progress li.last:after,
ol.inline.commerce-checkout-progress li.last:after {background: url("../images/bg_checkout_progress_last.png") no-repeat scroll right center #EFEFEF;}

ul.inline.commerce-checkout-progress li.active,
ul.inline.commerce-checkout-progress li.last.active,
ol.inline.commerce-checkout-progress li.active,
ol.inline.commerce-checkout-progress li.last.active {background-color: #939498;color: #FFFFFF;}

#commerce-checkout-form-complete .checkout-completion-message { padding: 1%; }
#commerce-checkout-form-complete .checkout-completion-message a { color: #C7CC43; }

.item-list ul li { padding: 0; }
#payment-methods {text-align: center;}
#highlighted {
	/*position: relative;*/
	width: 100%;
}
.container-center {margin: 0 auto; position: relative; overflow: hidden; /* Added to fix triangles left right */}
.container-highlighted-center {
	margin: 0 auto;
}
#services-summary {text-align: justify;}

/* Sticky footer */
* { margin: 0; }
html, body { height: 100%; }
#wrap { min-height:100%; height: auto !important; margin: 0 auto -70px; }
#footer, .push { height: 69px; clear:both; overflow: hidden;}

/* Hide Shopping cart banner block from Product pages */
.node-type-product-display #block-views-442b7a0245761e249e2ee7b2d18b9f08, .node-type-training-course-display #block-views-442b7a0245761e249e2ee7b2d18b9f08 {display: none;}

/*#edit-display-price*/
.rs-price-display {
   float: right;
   font-weight: bold;
   text-align: right;
   font-size: 2em;
   margin: 0;
}

/*.page-node .node-training-course-display .commerce-product-field-commerce-price .field-item*/
.rs-price-display .rs-price-sale {
  margin-left: 10px;
  color: #DE0E0E;
}

.commerce-add-to-cart .form-submit {
  background-color: #C7CC43;
  background-image: url("../images/bg_add_to_cart.png");
  background-position: 90% 50%;
  background-repeat: no-repeat;
  border: 0 none;
  border-radius: 8px 8px 8px 8px;
  color: #4C4C4C;
  margin-left: 5px;
  margin-top: 0.3em;
  padding: 5px 50px 5px 10px;
  font-size: 1.2em;
  float: right;
  cursor: pointer;
}

.commerce-add-to-cart .form-submit:hover {
	/*color: #939498;*/
	background-color: #DCE22B;
}

#sidebar-first .region-sidebar-first {margin-top: 2%;}
.front #content #main {margin-top: 2%;}

/*.node .horizontal-tabs .group-ainspection-testing .group-inspection-body {width:525px;}*/
#breadcrumb {margin: 0;}
.flex-direction-nav .flex-next { right: 2px; }
.flex-direction-nav .flex-prev { left: 2px; }
.flex-direction-nav a { height: 50px; }
.flex-direction-nav a:before { font-size: 40px; }
#sidebar-first {
	width: 260px;

}

/*aside .block .content {
	padding: 0px 10px;
}*/

.block > h2 {
	font-size: 1.5em;
	margin: 5px 0px 15px 0px;
	padding: 10px;
	overflow: hidden;
	text-align: left;
}

.container {
	margin: 0 auto;
	max-width: 1280px;
}

/*.container-wrap {

	max-width: 1280px;
	margin: 10px auto;
	padding: 1% 0;
	/* overflow: hidden; */
/*}*/

ul.menu {
	padding: 0;
	margin: 5px 0 5px 0;
}
/*.not-logged-in #header-right {width: 300px; margin-right:1%; float:right; margin-left: 0;}
.not-logged-in #header-left {float: left;margin-left: 1%;margin-right: 0;}*/
/*.logged-in #header-right {width: 455px; margin-right:1%;float:right;}*/
/*#header-left {
	width: 70%;
	float: left;
}
#header-right {
	width: 30%;
	float: right;
}*/

.node p a {
	color: #C7CC43;
	text-decoration: underline;
}

/*#content {
	margin-left: 25px;
	margin-right: 0;
	width: 960px;
}  */

/*.container-wrap #content {
	display: inline;
	margin-bottom: 10px;
	width: 100%;
}*/

/* Diagonal triangles for banner */
#triangle-left {height: 0; overflow: hidden;padding-bottom: 31.3%;padding-left: 10%;position: absolute;width: 0;z-index: 1;}
#triangle-left:after {border-bottom: 1555px solid rgba(0, 0, 0, 0);border-left: 500px solid #939498;content: "";display: block;height: 0;margin-left: -500px;width: 0;}
#triangle-right {height:0;overflow:hidden;padding-top:31.3%;position:absolute;right:0;top:0;width:10%;}
#triangle-right:after {border-right:160px solid #939498;border-top:500px solid rgba(0, 0, 0, 0);content:"";display:block;height:0;margin-top:-500px;width: 0;}

/* Main menu */
.toggle-menu-up h2:after {
  -moz-border-bottom-colors: none;
  -moz-border-left-colors: none;
  -moz-border-right-colors: none;
  -moz-border-top-colors: none;
  border-color: #C7CC43 rgba(136, 183, 213, 0) rgba(136, 183, 213, 0);
  border-image: none;
  border-style: solid;
  border-width: 8px;
  content: " ";
  height: 0;
  right: 0;
  margin-left: 0;
  pointer-events: none;
  position: absolute;
  top: 10px;
  width: 0;
}

.toggle-menu-down h2:after {
  -moz-border-bottom-colors: none;
  -moz-border-left-colors: none;
  -moz-border-right-colors: none;
  -moz-border-top-colors: none;
  border-color: rgba(136, 183, 213, 0) rgba(136, 183, 213, 0) #C7CC43;
  border-image: none;
  border-style: solid;
  border-width: 8px;
  top: 0;
  content: " ";
  height: 0;
  right: 0;
  margin-left: 0;
  pointer-events: none;
  position: absolute;
  width: 0;
}

.front .flexslider .slides .title-banner {
  position: absolute;
  display: block;
  cursor: pointer;
  bottom:0;
  height: 30px;
  opacity: 0.9;
  /*padding-left: 10px;*/
  padding-left: 45px;
  padding-top: 5px;
  z-index: 0;
  /* Fallback for web browsers that doesn't support RGBa */
  background: rgb(62, 56, 54) transparent;
  /* RGBa with 0.6 opacity */
  background: rgba(62, 56, 54, 0.5);
  /* For IE 5.5 - 7*/
  filter:progid:DXImageTransform.Microsoft.gradient(startColorstr=#993E3836, endColorstr=#993E3836);
  /* For IE 8*/
  -ms-filter: "progid:DXImageTransform.Microsoft.gradient(startColorstr=#993E3836, endColorstr=#993E3836)";
  text-align: left;
  color: #FAFAFA;
  font-size: 120%;
  width: 100%;
}

.front .flexslider .slides .toggle-menu-up:after {
  background-image: url('../images/b_arrow_down.png');
  content: ' ';
  display: block;
  width: 50px;
  height: 40px;
  position: absolute;
  top: 8px;
  background-repeat: no-repeat;
  right: 12%;
}

.front .flexslider .slides .toggle-menu-down:after {
  background-image:url('../images/b_arrow_up.png');
  content: ' ';
  display: block;
  width: 50px;
  height: 40px;
  position: absolute;
  top: 8px;
  background-repeat: no-repeat;
  right: 12%;
}


.slides li {
	position: relative; /* important */
}

.front .flexslider .slides .field-name-body {
	/*display: none; */
	height: 0;
  visibility: hidden;
}
#custom-flexslider-content .toggle-body {
  background-color: #3E3836;
  color: #B9B9B9;
  display: none;
  position: absolute;
  top: 0;
  z-index: 100;
  width: 100%;
}

#custom-flexslider-content .toggle-body span {
	display: block;
	padding: 10px;
}

/* Sales and Training */
.page-node .node-product-display .commerce-product-field-commerce-price .field-item span { display: none; }
.node-type-training-course-display  .content .group-right { text-align: right; }
.node-type-training-course-display  .content .group-right .field-name-node-training-logo img { width: 180px; }
.page-node .node-training-course-display .commerce-add-to-cart .form-submit {
  margin-top: 2%;
  background: #C7CC43;
  border: 0 none;
  border-radius: 8px 8px 8px 8px;
  color: #4C4C4C;
  margin-left: 5px;
  padding: 1% 2%;
  font-size: 1.2em;
}

.page-node .node-training-course-display .commerce-add-to-cart .form-button-disabled { margin-top: 2px; }
.page-node .node-training-course-display .commerce-add-to-cart .form-submit:hover { background: #DCE22B; }
.page-node .node-training-course-display .commerce-add-to-cart .form-button-disabled:hover { background: #C7CC43; }
.page-node .node-product-display .commerce-add-to-cart .form-item label { float: left; padding-top: 5px; }
.page-node .node-product-display .commerce-add-to-cart .form-item-quantity label,
.page-node .node-training-course-display .commerce-add-to-cart .form-item-quantity label { margin-right: 15px; float: left; margin-top: 5px;}
.page-node .node-product-display .commerce-add-to-cart .form-type-select label { margin-right: 23px; }
.page-node .group-footer .horizontal-tabs fieldset { background-color: #F7F7FA; }
.node-training-course-display .group-middle .field-label {float:left;}
.node-training-course-display .group-middle {margin-bottom: 15px;}
.page-node .node-product-display .group-header,
.page-node .node-training-course-display .group-header {
  background-color: #EDEDDD;
  border-top: 1px solid #C7CC43;
  border-left: 1px solid #C7CC43;
  border-right: 1px solid #CCCCCC;
  border-bottom: 1px solid #CCCCCC;
  margin: 0 0 1.5em !important;
  overflow: hidden;
  padding: 5px 5px 5px 10px;
  text-shadow: 1px 1px 0 #FFFFFF;
}

.page-node .group-footer .horizontal-tabs .group-htraining-course .field-items .field-label { float: left; width: 7em; }

/* Services page */
ul.block-services-categories { padding: 0; }
ul.block-services-categories li.last { margin: 0; }
ul.block-services-categories li { background-color: #676767;float: left;list-style: none outside none;margin-bottom: 1%;margin-right: 1%;padding: 0.5%;text-align: center;width: 18%;}
ul.block-services-categories li a {display: block;color: #FFFFFF;padding-bottom: 5%;padding-top: 5%;text-decoration: none;}
ul.block-services-categories li a:hover { color: #C7CC43; }

/* Shopping cart */
.block-commerce-cart{
	float:right;
	margin-top: 2%;
	margin-left: 10px;
}
.block-commerce-cart .cart-contents .view-commerce-cart-block .view-content,
.block-commerce-cart .cart-contents .view-commerce-cart-block .view-footer,
.block-commerce-cart .cart-contents .view-commerce-cart-block .view-empty {display: inline;float: left;font-weight: 400;}
.block-commerce-cart .cart-contents .view-commerce-cart-block .view-footer .line-item-total-label { display: none; }
.block-commerce-cart .cart-contents .view-commerce-cart-block .view-footer {display: block;float: none;}
.block-commerce-cart .cart-contents {background-color: #EDEDDD;background-image: url("../images/bg_add_to_cart_560.png");background-position: 1% 48%;background-repeat: no-repeat;clear: both;float: left;padding-left: 50px;}
.block-commerce-cart .cart-empty-block {
	background-color: #EDEDDD;
	background-image: url("../images/bg_add_to_cart_560.png");
	background-position: 4% 49%;
	background-repeat: no-repeat;
	color: #888;
	text-align: center;
	/*padding: 2% 2% 2% 18%;*/
	padding: 5px 10px 5px 40px;
}
.block-commerce-cart .cart-contents .line-item-summary {display: inline;float: left;height: 34px;line-height: 30px;margin: 0;}
.block-commerce-cart .cart-contents .line-item-summary .line-item-quantity {color: #C7CC43;text-decoration: none;}
.block-commerce-cart .cart-contents .line-item-summary .line-item-total {color: #888888;float: left;font-weight: 300;height: 100%;line-height: 30px;padding: 0 12px 0 6px;}
.block-commerce-cart .cart-contents .line-item-summary ul.links {background-color: #939498;clear: none;color: #FFF;display: block;float: left;font-weight: 300;height: 100%;line-height: 30px;padding: 0 10px;font-size: 100%;margin-top:0;}
.block-commerce-cart .cart-contents .line-item-summary ul.links:hover { background-color: #A9A9AA; }
.block-commerce-cart .cart-contents .line-item-summary ul.links a { color: #FFFFFF; display: block; }

#footer .block ul.menu li a {
	padding: 0;
}


/*********************************************/
/*               Working menu                */
/*********************************************/



/*********************************************/
/*          Custom Category Menu             */
/*********************************************/

/*.desktop-category-menu ul{
  padding: 0;
  list-style: none;
  margin: 0;
}
.desktop-category-menu ul li{
  position: relative;
  list-style-type: none;
  list-style-image: none;
  padding: 0;
}
.desktop-category-menu ul li a{
  display: block;
  padding: 5px 10px;
  color: #333;
  background: #f2f2f2;
  text-decoration: none;
}
.desktop-category-menu ul li a:hover{
  color: #fff;
  background: #939393;
}
.desktop-category-menu ul li ul{
  display: none;
  padding: 0;
  margin: 0;
}


.desktop-category-menu ul li:hover > ul {
  display: block;
  position: absolute;
  left: 100%;
  bottom: 0;
  z-index: 100;
  width: 100%;
}

.desktop-category-menu h2 {
	background: #f2f2f2;
  margin: 0;
  border-bottom: 1px solid #C7CC43;
  padding-bottom: 5px;
  padding-left: 10px;
}*/

.desktop-category-menu {
	padding: 0;
	margin-bottom: 20px;
}

.desktop-category-menu ul, .desktop-category-menu li {
	margin: 0;
	padding: 0;
}

.desktop-category-menu {
	position:relative;
	z-index:2;
	width: 100%;
}

/*.desktop-category-menu ul:first-child li {
	background: #f2f2f2;
	color: #333;
	width: 100%;
}*/

.desktop-category-menu ul:first-child > li {
	background: #f2f2f2;
	color: #333;
	width: 100%;
}

.desktop-category-menu ul li > ul li {
	/*background: #D4D4D4;*/
	background: #f2f2f2;
	width: 100%;
	line-height: 14px;
}

.desktop-category-menu ul li a
{
	padding:10px 15px;
	display:block;
	color:#333;
	text-decoration:none;
	border-right: 1px solid #EEE;
}

.desktop-category-menu ul li > ul li > a
{
	/*background: #D4D4D4;*/
	background: #f2f2f2;
}

.desktop-category-menu ul li {
	list-style-type: none;
  list-style-image: none;
  width: 100%;
}

.desktop-category-menu ul li a:hover
{
  color: #fff;
  background: #939393;
}

/*  works good up to here  */

.desktop-category-menu ul li > ul {
	display: none;
	float: left;
}

.desktop-category-menu ul li:hover > ul {
	display: block;
	position: absolute;
  top: 0;
  left: 100%;
  width: 200px;
  z-index: -1;

  -webkit-transition: left 200ms ease-in;

  -moz-transition: left 200ms ease-in;

  -ms-transition: left 200ms ease-in;

  transition: left 200ms ease-in;

  /* temporary */
  /*background: #D4D4D4;*/
  background: #f2f2f2;
  height: 440px;
}

/* Desktop - Gallery menu */
#lg-gallery-category ul li:hover > ul {
	height: 355px;
}

/* Desktop - Training menu */
#lg-training-category ul li:hover > ul {
	height: 250px;
}

.desktop-category-menu ul li:hover > ul li {
	width: 100%;
	/*background: #D4D4D4;*/
	background: #f2f2f2;
}


.desktop-category-menu ul li .hoverscroll {
	display: none;

	/* temporary */
	/*background: #D4D4D4;*/
	background: #f2f2f2;
  height: 440px;
  width: 200px;
}

/* Desktop - Gallery menu */
#lg-gallery-category ul li .hoverscroll {
	height: 355px;
}
/* Desktop - Training menu */
#lg-training-category ul li .hoverscroll {
	height: 250px;
}

.desktop-category-menu ul li:hover ul li:hover > ul,
.desktop-category-menu ul li:hover > .hoverscroll,
.desktop-category-menu ul li:hover ul li:hover > .hoverscroll {
	display: block;
	position: absolute;
	left: 100%;
	top: 0;
	/*background: #D4D4D4;*/
	background: #f2f2f2;
}

.desktop-category-menu ul li > ul li a:hover,
.desktop-category-menu ul li:hover > a {
	color: #fff;
  background: #939393;
}

.desktop-category-menu h2 {
  font-size: 1.5em;
	margin: 5px 0px 15px 0px;
	padding: 10px;
	text-align: left;
	font-weight: bold;
  color: #C7CC43;
  line-height: 50%;
  border-bottom: 1px solid #C7CC43;
}

/*********************************************/
/*             Custom Main Menu              */
/*********************************************/

.desktop-main-menu ul{
  padding: 0;
  list-style: none;
  margin: 0;
}
.desktop-main-menu ul li{
  position: relative;
  list-style-type: none;
  list-style-image: none;
  padding: 0;
}
.desktop-main-menu ul li a{
  display: block;
  padding: 5px 10px;
  color: #333;
  background: #f2f2f2;
  text-decoration: none;
}
.desktop-main-menu ul li a:hover{
  color: #fff;
  background: #939393;
}

.desktop-main-menu ul li > ul{
  display: none;
}

/* display the dropdown */
.desktop-main-menu ul li:hover > ul {
  display: block;
  position: absolute;
  left: 100%;
  top: 0;
  z-index: 100;
  width: 100%;
}

.desktop-main-menu h2 {
  background: #f2f2f2;
  margin: 0;
  border-bottom: 1px solid #C7CC43;
  padding-bottom: 5px;
  padding-left: 10px;
}

.desktop-main-menu div.rs-container {
  float:left;
  position:absolute;
  left:-999em; /* Hides the drop down */
  text-align:left;
  border:1px solid #777777;
  z-index: 1000;
  background:#F4F4F4;
  background: -moz-linear-gradient(top, #EEEEEE, #BBBBBB);
  background: -webkit-gradient(linear, 0% 0%, 0% 100%, from(#EEEEEE), to(#BBBBBB));
}

.desktop-main-menu div.rs-container {
  width: 640px;
  height: 340px;
}

.desktop-main-menu ul li ul li:hover div.rs-container {
  left: 100%;
  top: 0;
}

.desktop-main-menu ul li ul li:hover div.rs-container > ul {
  left: 100%;
  top: 0;
  z-index: 100;
  width: 33%;
}

/* Testing Main Menu */

.parent-menu li:hover > a,
.rs-container > ul li:hover > a {
  color: #fff;
  background: #939393;
}

#rs-desktop-main-menu, #rs-desktop-main-menu ul, #rs-desktop-main-menu li {
	margin: 0;
	padding: 0;
}

.rs-outer {
	/*overflow: auto;*/
	position:relative;
	z-index:2;
	width: 100%;
}


.parent-menu {
	background: #f2f2f2;
	width: 100%;
}

.parent-menu li ul.rs-services li {
	background: #d4d4d4;
	width: 100%;
}

.parent-menu li ul.rs-services li:hover  {
	color: #fff;
}

.parent-menu li ul.rs-services li ul li  {
	color: #333;
}

#rs-desktop-main-menu ul.menu li a {
	border-bottom: none;
}


#rs-desktop-main-menu ul li a
{
	padding:10px 15px;
	display:block;
	color:#333;
	text-decoration:none;
	border-right: 1px solid #EEE;
}

#rs-desktop-main-menu ul {
	list-style-type: none;
  list-style-image: none;
}


#rs-desktop-main-menu ul li a:hover
{
  color: #fff;
  background: #939393;
}

/* Parent list - Services item */
#rs-desktop-main-menu ul li > ul {
	display: none;
	float: left;
}

#rs-desktop-main-menu ul li:hover > ul {
	display: block;
	position: absolute;
  top: 0;
  left: 100%;
  width: 180px;
  z-index: -1;

  -webkit-transition: left 200ms ease-in;

  -moz-transition: left 200ms ease-in;

  -ms-transition: left 200ms ease-in;

  transition: left 200ms ease-in;

  /* temporary */
  background: #D4D4D4;
  height: 345px;
}
/* End of Parent list - Services item */

/* Container list */
#rs-desktop-main-menu ul li:hover ul.rs-services li:hover > ul {
	display: block;
	position: absolute;
  top: 0;
  left: 100%;
  width: 200px;
  z-index: -1;

  -webkit-transition: left 200ms ease-in;

  -moz-transition: left 200ms ease-in;

  -ms-transition: left 200ms ease-in;

  transition: left 200ms ease-in;

  /* temporary */
  background: #D4D4D4;
  height: 345px;
}

/* Third level */
#rs-desktop-main-menu ul li:hover ul li:hover > ul li {
	width: 100%;
}

.parent-menu li ul.rs-services {
	width: 100%;
}

.parent-menu li ul.rs-services li .rs-container,
.parent-menu li ul.rs-services li .rs-container ul li .hoverscroll,
.parent-menu li .rs-container,
.parent-menu li .rs-container ul li .hoverscroll {
	display: none;

	/* temporary */
	background: #D4D4D4;
  height: 345px;
  width: 200px;
}

.parent-menu li ul.rs-services li .rs-container ul li a,
.parent-menu li .rs-container ul li a {
	background: none;
	font-weight: normal;
}

.parent-menu li:hover ul.rs-services li:hover > .rs-container,
.parent-menu li:hover ul.rs-services li:hover .rs-container ul li:hover > .hoverscroll,
.parent-menu li:hover > .rs-container,
.parent-menu li:hover .rs-container ul li:hover > .hoverscroll {
	display: block;
	position: absolute;
	left: 100%;
	top: 0;
}

li.rs-services-item a {
	color: #fff;
}

#rs-desktop-main-menu ul li > ul li a:hover,
#rs-desktop-main-menu ul li:hover > a {
	color: #fff;
  background: #939393;
}

#rs-desktop-main-menu ul li > ul li {
	line-height: 14px;
}

.rs-responsive-device {
	display: none;
}


/****************************************************
 *
 * Media Queries
 *
 *
 ****************************************************/

/* Working */
@media screen and (max-width: 1299px) {
	.container { margin-right: 10px; margin-left: 10px; }
}

/* ------------- Media Query - 800x1279 -----------------------------------------------------------------*/

@media screen and (max-width: 1152px) {

}

@media screen and (max-width: 960px) {


}



/* Single Fluid Width Column for iPhone */
@media screen and (max-width: 800px) { /* was 785 */
  /*#wrap #sidebar-first {
		display: none;
	}*/
	#rs-main-menu {
		margin-bottom: 1px;
	}

	.rs-responsive-device {
		display: block;
	}

	.desktop-device {
		display: none;
	}

	aside .block .content {
  	padding: 0;
  }

	/* Hide Custom Main menu on devices */
	.custom-rs-main-menu {
		display: none;
	}

	#middle-container .content-wrap {
		width: 100%;
	}

	#wrap {
		min-height: auto;
		height: auto;
		margin: 0;
	}

	#footer, .push {
  	height: auto;
  }

  #footer ul.menu li {
  	text-align: center;
  	padding: 10px 0px;
  }

  #wrap #sidebar-first {
  	width: 100%;
  	margin: 0;
  }

  /**
   * Navigation - Sales Products
   */
  .rs-responsive-device h2 {
  	display: none;
  }

  .rs-responsive-device ul.slimmenu li a {
  	padding: 11px 10px;
  }

  .rs-responsive-device ul.slimmenu li.expanded ul li a {
  	padding: 11px 20px;
  }

  .rs-responsive-device ul.slimmenu li a.active-trail {
  	border-bottom: none;
  }

  .rs-responsive-device ul.slimmenu li {
  	border-bottom: 1px solid #ccc;
  	padding: 0;
  }

  .rs-responsive-device ul.menu li ul,
  .rs-responsive-device ul.slimmenu li.expanded ul {
  	margin: 0;
  }

  .rs-responsive-device ul.slimmenu li.expanded {
  	padding: 0;
  }

  .rs-responsive-device ul {
  	padding: 0;
  }

  #block-menu-menu-sales-product-categories ul.menu li ul {
  	margin: 0;
  }

  .flex-direction-nav a:before {
  	font-size: 30px;
  }

  /* FlexNav navigation for mobile */
  .main-menu-button,
	.category-menu-button {
	  position: relative;
	  display: block;
	  /*padding: 1em;*/
	  padding: 0.84em;
	  background: #a6a6a2;
	  color: #222222;
	  cursor: pointer;
	  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
	}
	.main-menu-button.one-page,
	.category-menu-button.one-page {
	  position: fixed;
	  top: 0;
	  right: 5%;
	  padding-right: 45px;
	}
	.main-menu-button .touch-button,
	.category-menu-button .touch-button {
	  background: transparent;
	  position: absolute;
	  z-index: 999;
	  top: 0;
	  right: 0;
	  width: 50px;
	  height: 51px;
	  display: inline-block;
	  text-align: center;
	}
	.main-menu-button .touch-button .navicon,
	.category-menu-button .touch-button .navicon {
	  font-size: 16px;
	  position: relative;
	  top: 1em;
	  color: #666;
	}

	/* General */
	li.expanded, li.collapsed, li.leaf {
		padding: 0;
	}

	ul.menu {
		margin: 0;
	}

	.flexnav-show li.first {
		border-top: 1px solid #222;
	}

	.horizontal-tabs ul.horizontal-tabs-list li {
		float: none;
	}


}

/* Single Fluid Width Column for iPhone */
@media screen and (max-width: 767px) {


}

/* Single Fluid Width Column for iPhone */
@media screen and (max-width: 640px) {

}

/* Single Fluid Width Column for iPhone */
@media screen and (max-width: 580px) {
  .logged-in #header-left {
  	width: 100%;
  	float: none;
  }

  .logged-in #header-right {
  	width: 100%;
  }

  #header {
  	position: relative;
  }

  .logged-in #header-right #block-system-user-menu {
  	position: absolute;
  	top: 0;
  	right: 0;
  	margin-right: 10px;
  }
}

/* Single Fluid Width Column for iPhone */
@media screen and (max-width: 540px) {
  ul.block-services-categories li {
		float: none;
		width: 100%;
		padding: 0;
	}

	#block-menu-menu-guest-menu {
		display: none;
	}

	.rs-price-display {
		float: none;
	}

	/*#edit-display-price {
    float: none;
	}*/

	.commerce-add-to-cart .form-submit {
		width: 100%;
	}

	.form-item {
		margin-bottom: 0.5em;
	}

	input.form-text  {
		width: 96%;
	}

	select.form-select {
		width: 99%;
	}
}

@media screen and (max-width: 480px) {
	.flex-direction-nav a:before {
  	font-size: 20px;
  }

  .flex-direction-nav a {
  	height: 25px;
  }

}
