
/* Table of Contents
==================================================

	#Page Styles
	#Media Queries
	#Font-Face */

/* #Page Styles
================================================== */

/* Layout Shortcodes */
.full-width {width:960px; height:auto; overflow:hidden}

.one_half { width: 48%; }
.one_third { width: 30.66%; }
.two_thirds { width: 65.33%; }
.one_fourth { width: 22%; }
.three_fourths { width: 74%; }
.one_fifth { width: 16.8%; }
.two_fifth { width: 37.6%; }
.three_fifth { width: 58.4%; }
.four_fifth { width: 67.2%; }
.one_sixth { width: 13.33%; }
.five_sixth { width: 82.67%; }

.one_half,
.one_third,
.two_thirds,
.three_fourths,
.one_fourth,
.one_fifth,
.two_fifth,
.three_fifth,
.four_fifth,
.one_sixth,
.five_sixth {
	position: relative;
	margin-right: 4%;
	margin-bottom: 20px;
	float: left;
}

.last {margin-right:0 !important;}

/* #Media Queries
================================================== */
	
	/* iPad Portrait/Browser */
	@media only screen and (min-width: 768px) and (max-width: 991px) {}
	
	/* Mobile/Browser */
	@media only screen and (max-width: 767px) {

	#breadcrumb { display: none; }

	.full-width,
	.one_half,
	.one_third,
	.two_thirds,
	.three_fourths,
	.one_fourth,
	.one_fifth,
	.two_fifth,
	.three_fifth,
	.four_fifth,
	.one_sixth,
	.five_sixth {width: 100%;}

	}
	
	/* Mobile Landscape/Browser */
	@media only screen and (min-width: 480px) and (max-width: 767px) {}
	
	/* Anything smaller than standard 960 */
	@media only screen and (max-width: 959px) {}
	
	/* iPad Portrait Only */
	@media only screen and (min-width: 768px) and (max-width: 991px) and (max-device-width: 1000px) {}
	
	/* Mobile Only */
	@media only screen and (max-width: 767px) and (max-device-width: 1000px) {}
	
	/* Mobile Landscape Only */
	@media only screen and (min-width: 480px) and (max-width: 767px) and (max-device-width: 1000px) {}
	

/* #Font-Face
================================================== */
/* 	This is the proper syntax for an @font-face file 
		Just create a "fonts" folder at the root, 
		copy your FontName into code below and remove
		comment brackets */
		
/*	@font-face {
	    font-family: 'FontName';
	    src: url('../fonts/FontName.eot');
	    src: url('../fonts/FontName.eot?iefix') format('eot'),
	         url('../fonts/FontName.woff') format('woff'),
	         url('../fonts/FontName.ttf') format('truetype'),
	         url('../fonts/FontName.svg#webfontZam02nTh') format('svg');
	    font-weight: normal;
	    font-style: normal; }
*/