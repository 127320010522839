.header-container {
  #header-right {
    .region.region-header-right {
      // @include clearfix;
      position: relative;

      #block-menu-menu-guest-menu,
      #block-block-8 {
        float: right;
      }

      #block-search-form {
        display: none;
        position: absolute;
        width: 200px;
        top: 46px;
        margin-right: -30px;
        z-index: 2000;
        @include breakpoint($mobile-big) {
          right: 30px;
        }
        @include breakpoint($tablet) {
          right: 30px;
        }
        @include breakpoint($desktop) {
          right: 0px;
          margin-right: -30px;
        }
      }

      form#search-block-form {
        input {
          display: block;
          width: 100%;
          height: 30px;
          margin: 7px 0;
        }
        input.form-submit {
          background: $green;
          color: #fff;
          border: 1px solid #fff;
          text-indent: 0px;
          background-image: none;
          font-weight: normal;
          text-shadow: none;
          box-shadow: none;
          @include border-radius(0px);
        }
      }

      // This is the click able search icon.
      div#block-block-8 {
        // background: red;
        margin-top: 15px;
        margin-right: 10px;
        padding-right: 10px;
        font-size: 18px;
        border-right: 2px solid black;
        cursor: pointer;
      }
    }
  }
}

