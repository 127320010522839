// The following is to enable the debugging in Singularity.
@include sgs-change('debug', true);

// Default padding
$default-padding: 0.5em;

// Breakpoints
$mobile-small: 320px; //(480px)
$mobile-big: 480px; //(480px)
$tablet: 48em; //(768px)
//$desktop: 64em; //(1024px)
$desktop: 1025px; //(1024px)

$iPad_landscape: (min-width 768px) (max-width 1024px) (orientation landscape);



/* iPads (landscape) ----------- */
//@media only screen and (min-device-width : ) and (max-device-width : 1024px) and (orientation : landscape) {
//  /* Styles */
//}