body.node-type-gallery {
  // The middle container (main content).
  #middle-container {

  .field-name-field-year-of-project {
    @include clearfix;
    .field-label, .field-items { float: left; }
  }

  /* The field of the views that contains the photos. */
    .field-name-field-photos {
      margin-top: 20px;
      .field-items {
        @include clearfix;
        .field-item {
          @include breakpoint($tablet) {
            lost-column: 1/2 2 1em;
            margin-bottom: 1em;
            img { width: 100%; }
          }
        }
      }
    }
  }
}
