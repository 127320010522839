@media screen and (max-width: 1300px) {

  #wrapper {
    /*max-width:960px;*/
    max-width: 1280px;
  }

  #top-area .column:last-child{
    margin-right: 0;
  }
  
  #copyright{
    padding: 0 10px;
  }
}

@media screen and (max-width: 960px) {

  
}

@media screen and (max-width: 640px) {

  /*#logo { text-align: center; width: 100%; }*/

  #copyright{ padding: 0 12px;}

  body { margin-top: 0; }
  
  .top_left, .top_right,
  .search_block, .region-user-menu{ width: 100%; }

  #footer p{ margin: 0; }

  #foot, #copyright{ padding: 0 10px; line-height: 200%; }

  .top_right .region-search{ width: 100%; padding: 0; }

  .social-icons{ width: 100%; text-align: center; }

  .social-icons { float: right; margin: 9px 0 0; }

  .front .content-sidebar-wrap{ margin: 0; }

  .region-front-welcome{ padding: 10px; }

  #site-title,
  #site-description { margin:0; line-height:1.62em; }
  
  #site-title { padding-top:18px; }
  
  #site-description { padding-bottom:18px; }

  #main-menu ul { display:none; }
  
  #main-menu li ul { position:static; border: 0; }
  
  #main-menu li ul ul { margin:0 !important; border: 0; }
  
  #main-menu ul,
  #main-menu li { float:none; max-width:100%; }

  #main-menu{ border:0; }
  
  #main-menu a {
    width: 100%;
    padding: 3% 2%;
    border-radius: 0;
    border-bottom: 1px solid #16A085;
  }

  #main-menu li li a { text-align:center; }
  
  #main-menu li:hover > ul,
  #main-menu li li:hover > ul,
  #main-menu li li li:hover > ul {
    display:none;
  }
  
  .drop-down-toggle { display:block; }
  
  #main-menu li li .drop-down-toggle { top:5px; }

  .menu_wrapper{ margin: 0; }

  #main-menu .nav-toggle {
    display: block;
    font-size: 15px;
    font-weight: normal;
    background: #18222B;
    color: white;
    margin: 0;
    padding: 3%;
    letter-spacing: 1px;
    text-align: center;
  }

  #main-menu ul.menu li{
    margin: 0;
    width: 100%;
    text-align: center;
  }

  .content-sidebar-wrap,
  #sidebar-first,
  #sidebar-second {
    float:none;
    width:auto;
  }

  .content-sidebar-wrap #content {
    /*float: left;*/
    /*width:auto;*/
    width: 100%;
    float: none;
  }
  
  #sidebar-first .block,
  #sidebar-second .block,
  #footer-area .column,
  #top-area .column {
    float:none !important;
    width:auto !important;
    margin-left:0;
  }
  
  #sidebar-first {
    clear:both;
  }
  
  #footer-area .column,
  #top-area .column {
    margin:10px;
  }

  #top-area .column:first-child,
  #footer-area .column:first-child{
    margin: 10px;
  }
  
  .copyright,
  .credits {
    float:none;
    text-align:left;
  }

  #footer_wrapper, #top-area { padding: 0; }

  #site-title{ width: 100%; }

  #site-title a{ width: 100%; text-align: center; }

  .top_right .region-user-menu ul.menu{ float: none; }

  /* #logo{ margin: 8px 0 0 0; text-align: center; }

  #logo img{ text-align: center; display: inline-block; }*/

  #site-title{ display: inline-block; padding: 0; }

  .theme-default .nivo-caption,.nivo-directionNav{ display: none !important; }

  .region-clients { margin: 5%; }

  .region-clients li { width: 49%; }

  #copyright{ padding: 4%; }

  #copyright p{ text-align: center; }

  #site-description{
    padding-bottom: 5px;
    width: 100%;
    text-align: center;
  }

}

@media screen and (max-width: 440px) {

  .block-menu ul{ float: none; text-align: center; }

  /*input.form-text, select.form-select { width: 93%; }*/

  textarea.form-textarea { width: 100% }

  .block > h2 { font-size: 18px; }

  .region-front-welcome p{ margin: 0; }

  .social-icons li{  padding: 0px;  text-align: center; }

  .node{  margin-top: 5px; }

  .region-clients li { width: 100%; }

}