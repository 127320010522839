#footer {
  .content {
    @include clearfix;
    .icons {
      max-width: 15%;
      margin: 0 auto;
      font-size: 130%;
      @include breakpoint($tablet) {
        max-width: 20%;
        float: left;
        padding-right: 10px;
      }
    }

    a:hover {
      color: initial;
    }
  }
}